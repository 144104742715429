<template>
    <div>
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.name" size="medium" placeholder="商品名" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto" v-show="btn_if.indexOf('bonus_mall_product_add') != -1">
                <el-button type="primary" size="small" @click="add">{{ "添加商品" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" @click="recordOfExchange">{{ "所有兑换记录" }}</el-button>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column prop="id" label="ID" />
                <el-table-column prop="id" label="商品图片">
                    <template slot-scope="{ row }">
                        <el-image style="width: 80px" :src="row.picture" :preview-src-list="[row.picture]" />
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="商品名称" />
                <el-table-column label="类型">
                    <template slot-scope="{ row }">
                        {{ typeList[row.type] }}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="数量">
                    <template slot-scope="{ row }"> {{ row.buyNumber }}/{{ row.number }} </template>
                </el-table-column>
                <el-table-column prop="bonus" label="消耗积分"> </el-table-column>
                <el-table-column prop="coin" label="消耗帆币"> </el-table-column>
                <el-table-column prop="viewNum" label="浏览次数"> </el-table-column>
                <el-table-column prop="buyPeople" label="浏览人数"> </el-table-column>
                <el-table-column prop="buyPeople" label="兑换人数">
                    <template slot-scope="{ row }">
                        <p>{{ row.buyPeople }}</p>
                        <p
                            style="color: tan; cursor: pointer"
                            @click="toList(row)"
                            v-show="btn_if.indexOf('bonus_mall_exchange_log') != -1"
                        >
                            兑换记录
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="{ row }">
                        <el-tag type="warning">{{ row.status ? "下架" : "上架" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" />
                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <el-button
                            v-show="btn_if.indexOf('bonus_mall_product_edit') != -1"
                            type="text"
                            icon="el-icon-edit"
                            size="mini"
                            style="color: skyblue"
                            @click="editor(row)"
                            >编辑</el-button
                        >
                        <el-button
                            v-show="btn_if.indexOf('bonus_mall_product_del') != -1"
                            type="text"
                            icon="el-icon-delete"
                            size="mini"
                            style="color: red"
                            @click="deletes(row)"
                            >删除</el-button
                        >
                        <el-button
                            v-show="btn_if.indexOf('bonus_mall_product_hide') != -1"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            style="color: pink"
                            @click="shelves(row)"
                            >{{ row.status == 0 ? "下架" : "上架" }}</el-button
                        >
                        <el-button
                            v-if="row.type == 1"
                            type="text"
                            icon="el-icon-upload"
                            size="mini"
                            :loading="row.loading"
                            @click="importEncryption(row)"
                            >导入虚拟卡密</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import { mapState } from "vuex";
import { productType } from "@/utils/dictionary";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                name: "",
                page: 1,
                pageSize: 10,
            },
            form: {
                name: "",
                page: 1,
                pageSize: 10,
            },
            typeList: productType,
        };
    },
    created() {
        this.getlist();
    },
    methods: {
        add() {
            this.$router.push("/product_add");
        },
        toList(row) {
            this.$router.push({
                path: "/product_record",
                query: {
                    id: row.id,
                },
            });
        },
        recordOfExchange() {
            this.$router.push("/product_record");
        },
        editor(row) {
            this.$router.push({
                path: "/product_add",
                query: {
                    id: row.id,
                },
            });
        },
        async importEncryption(row) {
            var formDate = await this.$store.dispatch("upLoad");
            formDate.append("productId", row.id);
            console.log(row.loading);
            row.loading = true;
            this.list = [...this.list];
            let { data: res } = await this.$http.post("/admin/BonusMall/importCodeExcel", formDate);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
            row.loading = false;
            this.list = [...this.list];
        },
        async deletes(row) {
            try {
                await this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                });
                let { data: res } = await this.$http.delete("/admin/BonusMall/productDel", {
                    params: {
                        id: row.id,
                    },
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                    if (this.total % 10 == 1) {
                        this.search.page--;
                    }
                    this.getlist();
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            }
        },
        async shelves(row) {
            // console.log(row.status);
            const { data: res } = await this.$http.post("/admin/BonusMall/updateProductStatus", {
                id: row.id,
                status: row.status ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        handleCurrentChange(e) {
            this.search.page = e;
            this.form.page = e;
            this.getlist(this.form);
        },
        async getlist(val) {
            const form = val || this.search;
            let { data: res } = await this.$http.get("/admin/BonusMall/getProductList", {
                params: {
                    ...form,
                },
            });
            this.list = res.data.list;
            this.total = res.data.totalCount;
        },
        subimt() {
            this.form = {
                ...this.search,
            };
            this.getlist();
        },
    },
    computed: {
        ...mapState({
            btn_if: (state) => state.btn_if,
        }),
    },
};
</script>

<style lang="less" scoped></style>
