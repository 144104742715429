var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"商品名"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"150px"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.subimt}},[_vm._v(_vm._s("搜索"))])],1),_c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.btn_if.indexOf('bonus_mall_product_add') != -1),expression:"btn_if.indexOf('bonus_mall_product_add') != -1"}],staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v(_vm._s("添加商品"))])],1),_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.recordOfExchange}},[_vm._v(_vm._s("所有兑换记录"))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"id","label":"商品图片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px"},attrs:{"src":row.picture,"preview-src-list":[row.picture]}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"商品名称"}}),_c('el-table-column',{attrs:{"label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.typeList[row.type])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.buyNumber)+"/"+_vm._s(row.number)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"bonus","label":"消耗积分"}}),_c('el-table-column',{attrs:{"prop":"coin","label":"消耗帆币"}}),_c('el-table-column',{attrs:{"prop":"viewNum","label":"浏览次数"}}),_c('el-table-column',{attrs:{"prop":"buyPeople","label":"浏览人数"}}),_c('el-table-column',{attrs:{"prop":"buyPeople","label":"兑换人数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.buyPeople))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.btn_if.indexOf('bonus_mall_exchange_log') != -1),expression:"btn_if.indexOf('bonus_mall_exchange_log') != -1"}],staticStyle:{"color":"tan","cursor":"pointer"},on:{"click":function($event){return _vm.toList(row)}}},[_vm._v(" 兑换记录 ")])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(_vm._s(row.status ? "下架" : "上架"))])]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btn_if.indexOf('bonus_mall_product_edit') != -1),expression:"btn_if.indexOf('bonus_mall_product_edit') != -1"}],staticStyle:{"color":"skyblue"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.editor(row)}}},[_vm._v("编辑")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btn_if.indexOf('bonus_mall_product_del') != -1),expression:"btn_if.indexOf('bonus_mall_product_del') != -1"}],staticStyle:{"color":"red"},attrs:{"type":"text","icon":"el-icon-delete","size":"mini"},on:{"click":function($event){return _vm.deletes(row)}}},[_vm._v("删除")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btn_if.indexOf('bonus_mall_product_hide') != -1),expression:"btn_if.indexOf('bonus_mall_product_hide') != -1"}],staticStyle:{"color":"pink"},attrs:{"type":"text","icon":"el-icon-s-promotion","size":"mini"},on:{"click":function($event){return _vm.shelves(row)}}},[_vm._v(_vm._s(row.status == 0 ? "下架" : "上架"))]),(row.type == 1)?_c('el-button',{attrs:{"type":"text","icon":"el-icon-upload","size":"mini","loading":row.loading},on:{"click":function($event){return _vm.importEncryption(row)}}},[_vm._v("导入虚拟卡密")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.search.page,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.search.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }